.pop_quizzes_container {
  padding: 20px;
  /* border-radius: 20px; */
  background: linear-gradient(
    117deg,
    rgb(255, 255, 255) 0%,
    rgb(232, 232, 232) 48%,
    rgb(227, 227, 227) 100%
    );
    background:   #d0e3d0 ;
  padding-inline: 40px !important;
  /* box-shadow: 0 0 10px #ececece6; */
}

.quizzes_container {
  margin-block: 40px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

