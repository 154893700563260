.s_card {
  /* width: 230px; */
  width:300px;
  border-radius: 20px;
  background: #FFF;
  padding: 5px;
  overflow: hidden;
  box-shadow: 2px 2px 10px #d8d8d8 ;
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-inline: auto;

}

.levelCardDesign {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin: 30px 0;
}

.levelGroupCardDesign{
  margin: 10px 0 !important;
}
.levelGroupCardDesign span{
  background: white;
  color:black;
  border: .1px solid transparent;

}
.levelCardDesign span:hover {
  /* filter: drop-shadow(2px 4px 6px black); */
  color:white;
  background: green;
  border: none;
}


.levelCardDesign span {
  padding: 5px 20px;
  border: .1px solid;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s ease-in-out;
}

.s_card:hover {
  transform: scale(1.05);
}

.s_card .top-section {
  height: 150px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(45deg, rgb(235, 235, 235) 0%, rgb(189, 189, 189) 100%);
  position: relative;
  overflow: hidden;
}

.s_card .top-section .s_border {
  border-bottom-right-radius: 10px;
  height: 30px;
  width: 130px;
  background: white;
  background: #fff;
  position: absolute;
  transform: skew(-40deg);
  box-shadow: -10px -10px 0 0 #fff;
}

.s_card .top-section .s_border::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  right: -15px;
  background: rgba(255, 255, 255, 0);
  border-top-left-radius: 10px;
  box-shadow: -5px -5px 0 2px #fff;
}

.s_card .top-section::before {
  content: "";
  position: absolute;
  top: 30px;
  left: 0;
  background: rgba(255, 255, 255, 0);
  height: 15px;
  width: 15px;
  border-top-left-radius: 15px;
  box-shadow: -5px -5px 0 2px #fff;
}

.s_card .top-section .icons {
  position: absolute;
  top: 0;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
}

.s_card .top-section .icons .logo {
  height: 100%;
  aspect-ratio: 1;
  padding: 7px 0 7px 15px;
}

.s_card .top-section .icons .logo .top-section {
  height: 100%;
}

.s_card .top-section .icons .social-media {
  height: 100%;
  padding: 8px 15px;
  display: flex;
  gap: 7px;
}

.s_card .top-section .icons .social-media .svg {
  height: 100%;
  fill: #3b3b3b;
}

.s_card .top-section .icons .social-media .svg:hover {
  fill: white;
}

.s_card .bottom-section {
  margin-top: 15px;
  padding: 10px 5px;
}

.s_card .bottom-section .s_title {
  display: block;
  font-size: 17px;
  font-weight: bolder;
  color: #2f2f2f;
  text-align: center;
  letter-spacing: 2px;
}

.s_card .bottom-section .row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.s_card .bottom-section .row .item {
  flex: 30%;
  text-align: center;
  padding: 5px;
  margin: 0;
  color: rgba(46, 46, 46, 0.721);
}

.s_card .bottom-section .row .item .big-text {
  font-size: 12px;
  display: block;
}

.s_card .bottom-section .row .item .regular-text {
  font-size: 9px;
}

.s_card .bottom-section .row .item:nth-child(2) {
  border-left: 1px solid rgba(52, 52, 52, 0.468);
  border-right: 1px solid rgba(52, 52, 52, 0.468);
}