.teachers_cards_container {
    margin: 60px auto;
    max-width: 1300px;
    width: 100%;
    transform: translateY(90px);
}

.teachers_cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 30px 0px;
    padding-bottom: 60px;
}

.teacher_card {
    background-color: #000;
    box-shadow: 0px 8px 16px rgba(0,0,0,.38), 0px 0px 30px rgba(0,0,0,.3);;
    border-radius: 10px;
    padding: 14px;
}



.teacher_card ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.teacher_card ul li {
    display: flex;
    align-items: center;
    gap: 15px;
    color: white;
}

.teacher_card ul li svg {
    width: 20px;
    height: 20px;
    stroke: white;
    fill: green;
}
.swiper, .swiper-wrapper{
    min-width: 100% !important;
    max-width: 100% !important;
    padding: 20px !important;
}
.swiper-slide{
    width: 420px 
}