.t_card_2_contaienr{
  background-color: #FFF;
  border-radius: 15px;
  padding: 15px;
}

.t_card_2_contaienr .image{
  width: 100%;
  height: 300px;
  border-radius: 15px ;
  overflow: hidden;
  
}



.t_card_2_contaienr .image img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.teacher2_name{
  text-align: center;
  margin-top: 15px;
  font-size: 20px;
  font-weight: bolder;
  color: #222;
}

.t_card_2_contaienr .infos{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.t_card_2_contaienr .infos .teacher_info{
  display: flex;
  align-items: center;
  gap: 5px;
  color: #343434;
  font-size: 17px;
}

  .t_card_2_contaienr .infos .teacher_info svg{
    color: #047b2d;
    font-size: 25px;
    
  }