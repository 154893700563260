.assessment_type_container{
  display: grid;
  grid-template-columns: repeat(2 , auto);
  gap: 10px;
}

.assessment_type_card{
  border: 1px solid #cdcdcd;
  transition: .2s ease;
  border-radius: 10px;
  padding: 10px 25px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.assessment_type_card.active:hover,
.assessment_type_card.active{
  
  background-color: var(--green-2);
}
.assessment_type_card.active *{
  color:#FFF
}

.assessment_type_card h3{
  font-size: 20px;
  font-weight: bolder;
}

.assessment_type_card svg{
  font-size: 50px;
  color: var(--green-2);
}
  .assessment_type_card:hover{
    background-color: #dfdfdf;
  }