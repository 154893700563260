
/* hand writing font */
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root{
  --yellow-1: #f59d38;
  --yellow-2: #e8a83f;
  --green-4: #7fb099;
  --green-3: #3d8865;
  --green-2: #3b732d;
  --green-1: #005f3a;

}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important; 
}

.hand-font {
  font-family: "Roboto", sans-serif !important;

}


h1, h1, h3,h4,h5,h6,a,p,span,section, div, pre, strong{
  font-family: "Roboto", sans-serif !important;

}

.math_font {
  font-family: math;
}

.sys_font {
  font-family: auto
}

@font-face{
  font-family: Katex;
  src: url('./assets/fonts/KaTeX_Main-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

.katex_font{
  font-family: katex !important;
}



.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.title{
  text-align: center;
  
}


a{
  text-decoration: none !important;
}

.ant-modal-root {
  z-index: 111231231231 !important;
  position: relative;
}

.green_btn{
  all: unset;
  padding:10px 20px;
  text-align: center;
  color: #FFF;
  width: 100%;
  border-radius: 6px;
  background-color: var(--green-2);
  font-weight: bolder;
  transition: .2s ease;
}
.green_btn.fit{
  width: fit-content;
}

.green_btn:hover{
  background-color: var(--green-1);
}

.st{
  font-size: 10px;
  
}
.stt{
  font-size: 12px;
  
}

.sttt{
  font-size: 14px;
}

.stttt{
  font-size: 16px;
}


.custom-steps .ant-steps-item {
  height: 100px; /* Adjust as needed */
}

.custom-steps .ant-steps-item-content {
  height: 200%; /* Ensure the content fills the item height */
  display: flex;
  align-items: center;
}

.custom-steps .ant-steps-item-active  .ant-steps-item-icon{
  background-color: var(--green-1) !important;
}

