.footer {
  width: 100%;
  padding: 20px 0;
  margin-top: 30px !important;
  background: #f8f9fa;
  border-top: 1px solid #eaeaea;
  position: relative;
  z-index: 1000;
}

.footer__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px !important;
  max-width: 1400px;
  margin: 0 auto;
  margin: auto;
  padding: 0 20px;
}

.footer__left,
.footer__right {
  display: flex;
  flex: .5 1;
}

.footer__logo img {
  height: 150px;
  transition: transform 0.3s ease-in-out;
}

.footer__logo img:hover {
  transform: scale(1.1);
}

.footer__info {
  margin-top: 10px;
  font-size:17px;
  font-weight: 700;
  color: black;
}


.footer__rights {
  margin-top: 10px;
  font-size: 14px;
  color: #6c757d;
}

.footer__column {
  margin-right: 30px;
}

.footer__title {
  font-weight: bold;
  margin-bottom: 10px;
}

.footer__item {
  margin-bottom: 8px;
  font-size: 14px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.footer__item:hover {
  color: green;
}

.footer__social {
  margin-left: auto;
}

.footer__social-links {
  display: flex;
  flex-direction: column;
}

.footer__social-link {
  margin-bottom: 8px;
  color: #333;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer__social-link:hover {
  color: #007bff;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer__content {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer__column {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .footer__social-links {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer__social-link {
    margin-right: 15px;
  }
}
