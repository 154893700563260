.header_container {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #eaeaea;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1231212123;
}

.header_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.left_side,
.right_side {
  display: flex;
  align-items: center;
}

.logo img {
  height: 80px;
  transition: transform 0.3s ease-in-out;
}

.logo img:hover {
  transform: scale(1.1);
}

.left_side_items {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.left_side_items a,
.item {
  color: #333;
  margin-right: 20px;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  position: relative;
  font-size: 18px;
}

.left_side_items a:hover,
.item:hover {
  color: #007bff;
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.mega_menu {
  display: none;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 280px !important;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.mega_menu.subjects{
  width: 1000px !important;

}
.mega_menu.subjects .mega_menu_column{
  width:100%;
}

.mega_menu_column {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: min(250px, 100%);
}

.mega_menu_item {
  padding: 10px 0;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.mega_menu_item:hover {
  color: rgba(0, 128, 0, 0.844);
  cursor: pointer;
}

.dropdown:hover .mega_menu {
  display: flex;
  opacity: 1;
  visibility: visible;
}

.right_side .login {
  color: #333;
  margin-right: 20px;
  font-size: 28px;
  cursor: pointer;
}

.btn_member {
  background: green;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.btn_member:hover {
  background: rgba(0, 128, 0, 0.844);
  transform: scale(1.05);
}

.header_subjects{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4 , 1fr);
}





/* Responsive Styles */
@media (max-width: 768px) {
  .header_content {
    flex-direction: column;
  }

  .left_side_items {
    flex-direction: column;
    margin-left: 0;
  }

  .left_side_items a,
  .item {
    margin: 10px 0;
  }

  .mega_menu {
    position: static;
    box-shadow: none;
  }
}



