
.question_card_container{
  width: 900px;
  margin-inline: auto;
}
.question_card_container .qc_title{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.question_card_container .queston_content{
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 0 10px #c0c0c0;
  
}

.question_card_container .queston_content .queston_content_left_side{
  font-size: 14px;
  
}


ol li div{
  display: flex;
  align-items: center;
  justify-content: space-between;

}


.q_title{
  margin-block: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.question_card_container .answers {
  margin-top: 10px;
}
.question_card_container .answers ol{
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: auto;
  padding-inline: 20px;

}

.zoom_pan{
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: .2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer ;
}

.zoom_pan:hover{
  background-color: #e6e6e6;
}

.question_right_side{
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 20px;
}

.mark_scheme_container{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
}

.mark_scheme_container .mark_scheme_content{
  padding: 20px;
}