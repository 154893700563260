


.customSlider{
  width: 90%;
  margin-inline: auto;
}

.cs_content{
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  background-color: #FFF;
  margin-bottom: 25px;
}

.customSlider .cs_btns{
  display: flex;
  align-items: center;
  justify-content: space-between;
}