

.checkboxes_container{
  display: flex;
  flex-direction: column;
  gap:7px;
}

.set_password_content{
  width: clamp(200px, 90%, 900px) !important;
}



.checkboxes_container h4{

}

.questions_container{
  display: grid;
  margin-top: 20px;

  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  
}

.checkboxes_container .checkbox_item{
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 17px;
}

