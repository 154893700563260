.signup_page{
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: calc(100vh - 200px); */
  z-index: 3;
  margin-block: 50px;
}

.signup_page .signup_content{
  width: clamp(200px, 50%, 800px);
  box-shadow: 0 0px 10px hsla(116, 74.70%, 82.90%, 0.84);
  padding: 20px;
  border-radius: 10px;
  position: relative;
  z-index: 10;
}



