.ibMath_topic_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    max-width: 1200px;
    margin: 20px auto;
    gap: 20px;
    padding: 64px 0px;
}

.ibMath_topics_boxes {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 30px;
}


.ibMath_topic_container .ibMath_topic_box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    max-width: 300px;
    width: 100%;
    border-radius: 10px;
    border-top: 10px solid green;
    padding: 0px 10px 10px 10px;
}

.ibMath_topic_box:hover {
    transform: translateY(-5px);
}

.ibMath_topic_container .ibMath_topic_box h3 {
    margin: 5px 0px;
    font-size: 1.5rem;
    font-weight: 700;
}

.ibMath_topic_container .ibMath_topic_box p {
    margin: 0px;
    max-width: 85% !important;
    width: 100%;
    margin: auto;
    color: rgb(20, 20, 20);
    line-height: 1.5;
    font-weight: 400;
}

.ibMath_topic_icon {
    background-color: rgba(0, 128, 0,0.1);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}

.ibMath_topic_icon svg {
    width: 40px;
    height: 40px;
}

.topic-icon_1 {
  background-color: #fee2fe;
}

.topic-icon_2 {
  background-color: #EDECFF;
}

.topic-icon_3 {
  background-color: #ecfef3;
}

.topic-icon_4 {
 background-color: #FFFDE7;
}

.topic-icon_1 svg {
  fill: rgb(145, 7, 145);
}

.topic-icon_2 svg {
  fill: rgb(92, 61, 245) !important;
}

.topic-icon_3 svg {
   fill: rgb(3, 152, 85) !important;
}

.topic-icon_4 svg {
  fill: rgb(245, 127, 23) !important  ;
} 