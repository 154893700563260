

.power_section_container{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 40px 0 ;
}

.power_section_content{
  
}

.power_section_content .title{
  font-size: 40px !important;
  margin-inline: auto;
  text-align: center;
  width: 1000px;
}

.power_items_container{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 50px;
  margin-inline: auto;
}


.power_item{
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  border: 2px solid hsla(138, 89.60%, 30.20%, 0.77);
  padding: 20px;
  animation: aniamtion .4s ease-out infinite alternate ; 
}

@keyframes aniamtion {
  from {
    transform: scale(.95);
  }
  to {
    transform: scale(1);
  }
}






.power_item .power_item_title{
  z-index: 3;
}
.power_item_info{
  z-index: 3;

}

.power_item >div{

}

.power_item .power_item_icon {
  z-index: 3;

}

.power_item .power_item_icon svg{
  max-width: 55px;
  border-radius: 50%;
}

.main_button{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.main_button button{
  background-color: var(--green-2);
  border: none;
  outline: none;
  padding: 10px 20px;
   color: #fff;
   font-size: 20px;
   /* border-radius: 9999px; */
   cursor: pointer;
   margin-top: 50px;
   transition: .2s ease !important;
  }

  .main_button button:hover{
    
    background-color: var(--green-1);
    
   }