.select-cat-card {
  padding: 32px;
  display: flex;
  
  background-color: var(--green-2);
  height: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: .3s ease;
}

.select-cat-card:hover{
  transform: scale(1.1);
}

.select-cat-card .hover_animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%) scale(0);
  transition: .3s ease-out;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform-origin: center center;
  cursor: pointer;
}
.select-cat-card:hover .hover_animation {
  width: 400px;
  height: 400px;
  transform: translate(-50% , -50%) scale(2);
  background-color: rgba(23, 205, 65, 0.37);

  
}

.select-cat-card .card-info {
  z-index: 3;
}

.select-cat-card .catrd-info .icon {
  /* margin-top: 84px;*/
}
.select-cat-card .card-info .card_title {
  color: #FFF;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: -0.38px;
  /* margin-top: 16px; */
}

.select-cat-card .card-info .activities {
  font-size: 16px;
  color: #FFF;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-top: 16px;
}

.select-cat-card .rating {
  width: 56px;
  height: 32px;
  border-radius: 16px;
  background: var(--Antique-White);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Maximum-Yellow-Red);
  font-size: 18px;
  font-weight: 500;
}


