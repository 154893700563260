.ibMath_banners {
    margin: 0px;
    background-color: rgba(0, 128, 0 , 0.2);
    position: relative;
    /* overflow: hiddens; */
    
}

.ibMath_banners .IB_banner_content{
padding:10px;

}

.ibMath_banners {
    z-index: 10 !important;
}

.ibMath_banners_box {
    margin-inline: 40px;
    margin-block: 10px;
    z-index: 10 !important;
    position: relative;

}

.ibMath_banners_box h1 {
    font-size: 3rem;
    z-index: 10 !important;
    

}
.ib_banner_button{
    position: relative;
    z-index: 2;
}

.ibMath_banners_box p {
    width: 75%;
    font-size: 1.2rem;
    color:rgb(20, 20, 20);
    z-index: 10 !important;

}


.ibMath_banners_link {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 40px 40px 0px 40px;
    position: relative;
    z-index: 10 !important;

}

.ibMath_banners_link svg {
    width: 30px;
    height: 30px;
}

.ibMath_banners_link a{
    color: #FFF !important;
    cursor: pointer;
}

.ibMath_banners_link .home-icon {
    display: flex;
    gap: 10px;
}

.ibMath_banners_link .home-icon svg {
    width: 20px;
    height: 20px;
}

.active_link{
    display: flex;
    gap: 10px;
   transition: all 0.3s;
   cursor: pointer;
}

.active_link:hover {
    text-decoration: underline;
}

.current_page {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.current_page svg {
    width: 17px;
    height: 17px;
    margin: auto;
}

.dropDown {
    position: relative;
    top: 80px;
    right: 170px;
    padding: 0px 15px;
    color: rgb(82, 82, 82);
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgb(230, 230, 230);
    box-shadow: rgba(16, 24, 40, 0.1) 0px 10px 15px -3px, rgba(16, 24, 40, 0.1) 0px 4px 6px ;
    z-index: 9999;
}

.dropDown p {
    /* padding-top: 3px;
    padding-bottom: 3px;    */
    font-size: 1rem;
    font-weight: 400;
}