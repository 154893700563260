


.facebook-video-player {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 12px; /* Rounded corners */
  overflow: hidden;
  background: #000; /* Background color similar to Facebook's dark overlay */
}

.facebook-video-player .react-player {
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

/* Custom controls styling */
.facebook-video-player .react-player .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6); /* Dark overlay */
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.facebook-video-player .react-player .volume-control,
.facebook-video-player .react-player .fullscreen-control {
  /* Style volume and fullscreen controls if needed */
}
