.container-unique {
  font-family: 'Arial, sans-serif';
  margin: 0 auto;
  padding: 2rem;
  /* background-color: #f4f4f4; */
  color: #333;
  max-width: 1200px;
}

.headline-amazing {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 1rem;
}

.subheadline-incredible {
  font-size: 1.5rem;
  color: #34495e;
  text-align: center;
  margin-bottom: 1rem;
}

.award-winning {
  font-size: 1.2rem;
  color: #16a085;
  text-align: center;
  margin-bottom: 2rem;
}

.description-detailed {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.card {
  background-color: #fff;
  border-radius: 12px;
  padding: 1.5rem;
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); */
  display: flex;
  cursor:pointer;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.card-icon {
  font-size: 3rem;
  color: #3498db;
  margin-bottom: 1rem;
}

.card-content {
  font-size: 1rem;
  color: #333;
  flex: 1;
}

.quality-top ul {
  list-style-type: none;
  padding: 0;
}

.quality-top li {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.invitation-join {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  text-align: center;
}


.card {
  width: 250px;
  max-height: fit-content;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  gap: 13px;
  position: relative;
  overflow: hidden;
  border:none !important;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062);
}

#cookieSvg {
  width: 50px;
}

#cookieSvg g path {
  fill: rgb(97, 81, 81);
}

.cookieHeading {
  font-size: 1.2em;
  font-weight: 800;
  height:50px;
  color: rgb(26, 26, 26);
}

.cookieDescription {
  text-align: center;
  font-size: .9em;
  font-weight: 600;
  color: rgb(99, 99, 99);
}

.cookieDescription a {
  --tw-text-opacity: 1;
  color: rgb(59 130 246);
}

.cookieDescription a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.buttonContainer {
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.acceptButton {
  width: 80px;
  height: 30px;
  background-color: #7b57ff;
  transition-duration: .2s;
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px #977ef3, 0 2px 4px -1px #977ef3;
  transition: all .6s ease;
}

.declineButton {
  width: 80px;
  height: 30px;
  background-color: #dadada;
  transition-duration: .2s;
  color: rgb(46, 46, 46);
  border: none;
  cursor: not-allowed;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px #bebdbd, 0 2px 4px -1px #bebdbd;
  transition: all .6s ease;
}

.declineButton:hover {
  background-color: #ebebeb;
  box-shadow: 0 10px 15px -3px #bebdbd, 0 4px 6px -2px #bebdbd;
  transition-duration: .2s;
}

.acceptButton:hover {
  background-color: #9173ff;
  box-shadow: 0 10px 15px -3px #977ef3, 0 4px 6px -2px #977ef3;
  transition-duration: .2s;
}