.assessment_title{
  cursor: pointer;
  border: 1.5px solid var(--green-2);
  padding: 5px 20px;
  border-radius: 999px;
  transition: .2s ease;
}

.assessment_title.active{
  background-color: var(--green-2);
  color: #FFF;
}


.assessmet_course_container{
  display: grid;
  grid-template-columns: repeat(2 , 1fr);
  gap: 20px;

}

.assessment_course_level{
  border: 1px solid #cdcdcd;
  transition: .2s ease;
  border-radius: 10px;
  padding: 10px 25px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  
}
.assessment_course_level.active:hover,
.assessment_course_level.active{
  
  background-color: var(--green-2);
  border: 1.5px solid var(--green-2);
  
}
.assessment_course_level.active *{
  color:#FFF
}

.assessment_course_level p{
  margin: 0;
  font-size: 13px;
  line-height: 30px;
}

.assessment_course_level:hover{
  background-color: #dfdfdf;
}