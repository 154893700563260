
.progress_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width:100%;
}

.porgress_bar{
  width: 100%;
  height: 14px;
  background-color: rgb(244, 85, 85);
  border-radius: 9000px;
  overflow: hidden;
}

.porgress_bar .flag_bar{
  height: 100% !important;
  width: 30% !important;
  background-color: rgb(0, 206, 117);
  border-radius: 9000px;

}

