.teacher-banner-container {
    /* background-image: url(../../../assets//images/page-title.22db88fc.jpg); */
    /* background-image: url(../../../assets/images/istockphoto-1468138682-170667a.webp); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 75vh;
    position: relative;
}

.teacher-banner-container video {
    width: 1300px;
    background-color: beige;
    height: 75vh;
}

.teacher-banner-search {
    padding: 45px 32px 38px 37px;
    background-color: #fff;
    box-shadow: 0 27px 18px 0 rgba(0, 0, 0, .06);
    max-width: 70%;
    width: 100%;
    z-index: 99;
    position: absolute;
    bottom: -67px;
    margin: 0px auto;
    left: 170px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
}

.teacher-banner-search select ,
.teacher-banner-search input {
    background-color: #f5f5f5;
    border: 1px solid #ebebeb;;
    padding: 15px 25px;
    font-size: 16px;
    border-radius: 5px;
    width: 100%;
    outline: none;
}

.list{
    width: 100%;

}

.teacher-banner-search button {
    background-color: green;
    color: white;
    padding: 15px 17px;
    border-radius: 5px;
    border: 1px solid green;
}