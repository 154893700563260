.SubjectsBanner {
  height: 80vh;
  width: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.448);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.SubjectsBanner video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.containerFilter {
  z-index: 122313;
  position: relative;
  width: 90%;
  background-color: rgba(0, 128, 0, 0.277);
  margin: -50px auto 20px auto;
  padding: 10px;
}
.containerFilter form {
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-block: 25px;
}
.containerFilter form input,
.containerFilter form select {
  outline: none;
  border: none;
  padding: 10px;
}
.containerFilter form select {
  width: 120px;
}
.containerFilter form .price {
  position: relative;
}
.containerFilter form .price input::-webkit-outer-spin-button,
.containerFilter form .price input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.containerFilter form input,
.containerFilter form select {
  width: min(35%, 100%);
  padding: 15px 20px;
  border-radius: 5px;
  cursor: pointer;
  /* padding: 1; */
}

.containerFilter form select option {
  cursor: pointer;
}
.containerFilter form .price span {
  display: inline-block;
  color: #000;
  background-color: #ddd;
  position: absolute;
  top: 0;
  left: 0;
}
.containerFilter form button {
  border: none;
  outline: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  color: #fff;
  padding: 11px 63px;
  border-radius: 5px;
  cursor: pointer;
  /* width: 100%; */
  min-width: 240px;
  /* padding: 18px; */
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: #fff;
  text-transform: capitalize;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
@media (max-width: 1185px) {
  .containerFilter form {
    flex-flow: column;
    gap: 20px;
  }
  .containerFilter form input,
  .containerFilter form select,
  .containerFilter form .price,
  .containerFilter form button {
    width: 90%;
  }
}
.containerFilter .content {
  background-color: var(--mainColor);
  padding-block: 20px;
  text-align: center;
  color: #fff;
}
.containerFilter .content h3 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: normal;
  text-transform: capitalize;
}
.containerFilter .content p {
  font-style: italic;
}

.sub-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
   z-index: 21323123;
  position: relative;
  letter-spacing: 0.11em;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
  padding-left: 5px;
}
.title-slide.wow {
  font-size: 76px;
  line-height: 83px;
  text-transform: capitalize !important;
  color: #fff;
  margin-bottom: 28px;
  z-index: 21323123;
  position: relative;
}


.slide-content .description {
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 40px;
    text-transform: capitalize;
    margin-left: 5px;
    letter-spacing: .005em;
    z-index: 21323123;
    position: relative;
}

.btn-main .button_main_inner {
    display: inline-block;
    background: #d01818;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    z-index: 21323123;
    position: relative;
    text-align: center;
    border-radius: 3px;
    transition: .4s;
    padding: 15px 44px;
    margin-bottom: 30px;
}


.slide-content {
    width: min(600px, 100%);
    margin-right: auto;
    padding: 0 0 0 60px;
}