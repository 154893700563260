.faq_container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .faq_box_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .faq_box {
    background: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .faq_box:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    transform: scale(1.02);
  }
  
  .question {
    background: green;
    flex-direction: row-reverse;
    color: #fff;
    padding: 15px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background 0.3s ease;
}
  
  .question:hover {
    background: rgba(0, 128, 0, 0.764);
  }
  
  .question span {
    font-size: 20px;
  }
  
  .question h3 {
    margin: 0;
    color: #fff;
    font-size: 16px;
  }
  
  .faq_box .answer {
    background: #fff;
    padding: 15px 20px;
    border-radius: 0 0 8px 8px;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s ease, opacity 0.5s ease;
  }
  
  .faq_box.open .answer {
    max-height: 200px; /* Adjust based on content */
    opacity: 1;
  }
  
  .faq_box.open .question {
    background: black;
  }
  
  .faq_box .answer p {
    margin: 0;
    line-height: 1.6;
  }
  