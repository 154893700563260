

.practice_style_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.practice_style_container > p{
  text-align: center;
  width: 800px;
  margin-inline: auto;
  margin-top: 20px;
  font-size: 20px;
  color: #4c4c4c;
}


.questions_cards_container{
  display: flex;
  flex-direction: column;
  gap: 60px;
}
