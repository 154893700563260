.strange-card {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min(400px, 100%);
    transition:.5s ease-in-out;
    border-radius:8px;
    height: 560px;

}

.strange-card:hover{
  translate: 0 -10px;
  /* scale: .9 */
}

.subjscardteach{
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
  width:100%;
  gap:10px;
  margin-bottom:10px
}

.strange-card .text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.strange-card .text img {
  height: 170px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.strange-card .text h3 {
  font-size: 40px;
  font-weight: 400;
}

.strange-card .text p:nth-of-type(1) {
  color: rgb(35, 182, 219);
  font-size: 15px;
  margin-top: -5px;
}

.strange-card .text p:nth-of-type(2) {
  margin: 10px;
  width: 90%;
  text-align: center;
}

.strange-card .links {
  width: 30%;
  display: flex;
  justify-content: space-evenly;
}

.strange-card .links i {
  color: rgb(35, 182, 219);
  font-size: 20px;
  cursor: pointer;
}

.strange-card .links i:hover {
  color: rgb(29, 157, 189);
}
