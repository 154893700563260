/* InputList.css */

.input-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.input-item input[type="file"] {
  margin-right: 10px;
}

.input-item input[type="text"] {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.input-item button {
  margin-left: 10px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.input-item button:hover {
  background-color: #0056b3;
}

button.add-item {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

button.add-item:hover {
  background-color: #218838;
}

.input-item {
  display: flex;
  align-items: end;
  height: 100px;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* TableStyles.css */

#styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

#styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

#styled-table th,
#styled-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

#styled-table tbody tr {
  border-bottom: 1px solid #ddd;
}

#styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

#styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

#styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

.xCountry span.xContent {
  display: flex;

  flex-direction: column;
  gap: 10px;
}

.xCountry span span{
    width:fit-content;
    display:inline-block
}
#middle-wizard > div.xCountry > span.xContent > span{
    width:100% !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row;
}
.xCountry > span:first-child {
  font-weight: 900;
  display: flex;
  flex-direction: column;
}
.xCountry {
  border: 1px solid;
  padding: 10px;
  border-radius: 8px;
}


/* CheckBoxComponent.css */

.checkbox-group {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 300px;
}

.checkbox-group label {
  margin: 10px 0;
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.status {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}
