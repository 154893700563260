

.subjects_page{
  background-color: #FFF;
}

.subjects_page .banner{
  width: 100%;
  height: 300px;
}

.subjects_page .banner img{
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.subjects_page .filters{
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  transform: translateY(-50%);
  background-color: #FFF;
  padding-block: 25px;
  box-shadow: 2px 4px 20px #b1b1b1;
  border-radius: 20px;
  padding-inline: 20px;
  
}

.subjects_page .filters select,
.subjects_page .filters input{
  border: none;
  outline: none;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 5px;
  transition: .2s ease;
  border: 1px solid  #0f5b19d8;
  width:100%
  
}

.subjects_page .filters input{
  border-radius: 5px 0 0 5px !important ;
}

.subjects_page .filters select:focus,
.subjects_page .filters input:focus{
  box-shadow: 0 0 0px 3px #0f5b1984 ;
}


.subjects_page .search{
  display: flex;
  flex: 1;
  justify-content: center;
}

.subjects_page .search button{
  all: unset;
  background-color: var(--green-2);
  color:#FFF;
  padding-inline: 20px;
  cursor: pointer;
  transition: .2s ease;
  border-radius: 0 5px 5px 0 !important ;

}

.subjects_page .search button:hover{
  background-color: var(--green-1);
  
}


.subjects_container{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  margin-inline: auto;
  gap: 40px;
  align-content: center;
}