
.searchInput{
  background-color: rgb(243, 243, 243);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  gap: 10px;
} 




.searchInput > input{
  border: none !important;
  outline: none !important;
background-color: transparent;
padding: 3px;
width: 200px;
}

