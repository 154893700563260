.stepper-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .stepper-item {
    font-size: 14px;
  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 1.5px solid #ccc;
    width: 100%;
    top: 15px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 1.5px solid #ccc;
    width: 100%;
    top: 15px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #FFF;
    font-size: 13px;
    background: #ccc;
    margin-bottom: 6px;
  }
  
  .stepper-item.active {
      font-weight: bold;
      color: var(--green-2);
    }
    .stepper-item.active .step-counter{
        border: 1.5px solid var(--green-2);
    }
    
  .stepper-item.completed .step-counter {
    background-color: var(--green-2);
    color: #FFF;
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 1.5px solid var(--green-2);
    width: 100%;
    top: 15px;
    left: 50%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
  