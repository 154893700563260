@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
}

button {
    cursor: pointer;
}

input {
    outline: none;

}

a {
    color: #000;
    text-decoration: none;
}

ul {
    list-style: none;
}

body {
    background-color: #fbfafa;
}

.no-border {
    border: none !important;
}

.author {
    padding: 2rem 0;
    background-color: #eef2fe;
}

.author-container {

    width: 75%;
    margin: auto;
}

.authorFlex {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.author-img {
    width: 100%;
    max-width: 180px;
}

.author-img img {
    border-radius: 50%;
    width: 100%;
}

.authorName h1 {
    margin-bottom: 0.5rem;
    font-size: 3rem;
    font-weight: 700;
    color: #000;
    line-height: 1.2;
    text-transform: capitalize;
}

.authorName h2 {
    color: #5a5a5a;
    font-size: 1.5rem;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 1.2;
    text-transform: capitalize;
}

.aboutContainer {
    width: 80%;
    margin: auto;
    padding: 30px 0;
}

.capFlex {
    display: flex;
    align-items: flex-start;
    margin: 0px -12px;
    flex-wrap: wrap;
}


.col-1-cap {
    width: 65%;
    padding: 0px 12px;
}


.authorTitle {
    margin-bottom: 15px;
    font-weight: 900;
    font-size: 20px
}


.authorList {
    list-style: disc;
    padding-left: 3rem;
}

.authorList li {

    margin-bottom: 15px;
    font-size: 17px;
    line-height: 1.6;
}

.quote {
    padding-left: 3rem;
    font-style: italic;
    letter-spacing: 0.7px;
    line-height: 1.3;
    margin-bottom: 15px;
}


.certificate-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 3rem;
}

.certificate-flex img {
    width: 180px;
    margin-right: 25px;
    margin-bottom: 15px;
}



.col-2-cap {
    width: 25%;
    padding: 0px 12px;
}

.col-2-container {
    background-color: rgba(255, 255, 255, 1);
    padding: 1.5rem;
}

.authorSocial p {
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: uppercase;
}


.social-media {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0;
    margin: 0;
}

.social-media li {
    margin: 1rem 0;
}


.social-media svg {
    font-size: 30px;
}


.col-3-cap {
    width: 100%;
}


.vid-div {
    margin-top: 30px;
}

.vid-div video {
    width: 60%;
    border-radius: 5px;
    height: 400px;
}

.col-4-cap {
    width: 100%;
    /* padding: 0 12px; */
}

.border-bottom {

    margin: 3rem 0;
    border-bottom: 1px solid #e2e2e2;
    width: 100%;
}

.article {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: capitalize;
}





.subjectFlex {
    display: flex;
    margin: 0 -12px;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 25px;
}

.col-subject {
    width: 33.33333%;
    padding: 0 12px;
}

.subjectContainer {
    padding: 15px 10px;
    border-radius: 6px;
    cursor: pointer;
}

.subjectImg {
    width: 100%;
    margin-bottom: 15px;
}

.subjectImg img {

    width: 100%;
    border-radius: 10px;
}

.lastFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.sub {
    font-size: 1.7rem;
    margin-bottom: 0.2rem;
    font-weight: bold;
}

.articleText {
    color: #6e6e6e;
    font-size: 16px;
    font-weight: 500;
}

.tagLead {
    margin-right: 5px;
}

.arrow {
    text-align: end;
}

.arrowDiv {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #265bf6;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid transparent;
    transition: opacity 0.6s ease;
    opacity: 0;
    text-align: end;
    margin-top: 10px;
}


.subjectContainer:hover .arrowDiv {
    opacity: 1;
}

.rateFlex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
}

.studyFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.rateFlex svg {
    color: #FFD43B;
}

.rate {
    margin: 0 15px;
    font-size: 18px;
    font-weight: 800;
}

.student {
    color: #6e6e6e;
    font-size: 14px;
    font-weight: 400;
}