.ibm_resources_container {
    max-width: 1000px;
    margin: 60px auto;
}

.ibm_resources_container h2 {
    text-align: center;
    font-size: 2.125rem;
    font-weight: 700;
    margin: 30px 0px;
}

.ibm_resources_boxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin-top:  64px;
}

.ibm_resources_boxes .ibm_resources_box h3 {
    font-size: 1.3rem;
    text-align: center;
    font-weight: 600;
    line-height: 1.2;
}

.ibm_resources_box {
    transition: all 0.3s;
    cursor: pointer;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    max-width: 300px;
    width: 100%;
    border-radius: 10px;
    border-top: 10px solid green;
    padding: 0px 10px 10px 10px;
}

.ibm_resources_box:hover{
   transform: translateY(-10px);
}

.ibm_resources_icon {
    background-color: rgba(0, 128, 0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px auto;
}

.ibm_resources_icon svg {
    width: 30px;
    height: 30px;
    color: rgba(0, 128, 0, 0.664);
}

.resource-icon-1 {
  background-color: rgba(66, 93, 245,0.1) !important;
}

.resource-icon-2 {
  background-color: rgba(245, 150, 66,0.1) !important;
}

.resource-icon-3 {
  background-color: rgba(141, 66, 245 , 0.1) !important; 
}



.resource-icon-1 svg {
 color: rgb(66, 93, 245) !important;
}

.resource-icon-2 svg {
  color:rgb(245, 150, 66) !important ;
}

.resource-icon-3 svg {
 fill: rgb(141, 66, 245) !important;
 color: rgb(141, 66, 245) !important;
 /* stroke: rgb(141, 66, 245) !important;; */
}
