.subj_sections_container{
  padding: 40px; 
  /* background-color: var(--green-2); */
  background-color: #FFF;
  color: var(--green-2);
  position: relative;
}

.subj_sections_container .banner_content{
  display: grid;
  grid-template-columns: repeat(2 , 1fr);
  
}

.subj_sections_container .section_top {
 margin-top: 20px;
 display: flex;
 flex-direction: column;
 gap: 25px;
 z-index: 2;
}



.subj_sections_container .section_top h1{
  font-size: 40px;
  font-weight: 500;
}

.subj_sections_container .section_top h4{
  font-size: 20px;
  width: 600px;
}

.subj_sections_container .section_top h5{
  font-size: 17px;
  color: var(--yellow-1) !important;
}

.subj_sections_container  .subjects{
  margin-top: 40px;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(3 , 1fr);
 z-index: 2;
 position: relative;
 
}

 .subj_sections_container .section_right{
  }
  .subj_sections_container .section_right img{
    width: 100%;
    user-select: none;
    -webkit-user-drag: none;
  }
  

.wave_1{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.wave_2{
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.wavy_arrow{
  position: absolute;
  top: -200px;
  right: 10px;
  font-size: 40px;
}

.wavy_arrow{
  
}

.wavy_arrow svg{
  transform: rotate(180deg) translate(100% , 40px);
  fill: #FFF;
  width: 100px;
}


.vid_icon{
  font-size: 50px;
  line-height: 0;
  cursor: pointer;
}


/* Define the animation */
@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Define the keyframe animations for shadow appearing and disappearing */
@keyframes shadow-appear-disappear {
  0% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* Shadow not visible */
  }
  50% {
    transform: scale(1.2);
    opacity: 0.6;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); /* Shadow visible */
  }
  100% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* Shadow not visible */
  }
}

/* Define the keyframe animations for shadow appearing and disappearing */
@keyframes shadow-appear-disappear {
  0% {
    opacity: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* Shadow not visible */
  }
  50% {
    opacity: 1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Shadow visible */
  }
  100% {
    opacity: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* Shadow not visible */
  }
}

/* Base styling for the vid_icon */
.vid_icon {
  position: relative;
  display: inline-block;
  color: green; /* Example color */
}

/* ::before and ::after pseudo-elements for additional shadows */
.vid_icon::before,
.vid_icon::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  z-index: -1; /* Place behind the main icon */
  animation: shadow-appear-disappear 2s ease-in-out infinite;
}

/* Specific shadow styling for ::before and ::after */
.vid_icon::before {
  animation-delay: 0s; /* Starts immediately */
  box-shadow: 0 0 30px rgba(255, 0, 0, 0.5);
}
