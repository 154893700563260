
.ibMath_topic_box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  max-width: 300px;
  width: 100%;
  border-radius: 10px;
  border-top: 10px solid green;
  padding: 20px;
}

.ibMath_topic_box:hover {
  transform: translateY(-5px);
}


.ibMath_topic_box h3 {
  margin: 5px 0px;
  font-size: 1.5rem;
  font-weight: 700;
}


.ibMath_topic_box p {
  margin: 0px;
  max-width: 85% !important;
  width: 100%;
  margin: auto;
  color: rgb(20, 20, 20);
  line-height: 1.5;
  font-weight: 400;
}

.ibMath_topic_icon {
  background-color: rgba(0, 128, 0,0.1);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.ibMath_topic_icon svg {
  width: 40px;
  height: 40px;
}