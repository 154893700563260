.ibMath_award_container {
    max-width: 1100px;
    /* background-color: rgba(0, 128, 0 , 0.3); */
    border-radius: 30px;
    padding: 30px 20px;
    margin: 20px auto;
}

.ibMath_award_container h2 {
    font-size:  2.125rem;
    font-weight: 700;
    text-align: center;
}

.ibMath_award_boxes {
  display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 30px;
    margin: 40px 0px;
}

.ibMath_award_box h3 , 
.ibMath_award_box p {
  margin: 0px;
}

.ibMath_award_container .ibMath_award_box {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    max-width: 300px;
    width: 100%;
    border-radius: 10px;
    border-top: 10px solid green;
    padding: 0px 10px 10px 10px;
}


.ibMath_award_box h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 6px;
}

.ibMath_award_box p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    max-width: 80%;
    margin: 0px auto;
    padding-bottom: 10px;
}


.ibMath_award_icon {
    background-color: rgba(0, 128, 0,0.1);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto 0px auto;
}

.award_icon_1 {
    background-color: rgba(226, 135, 67,0.1);
}

.award_icon_2 {
 background-color: rgba(37, 150, 190,0.1);
}

/* .award_icon_3 {

} */

.award_icon_1  svg{
    fill:#e28743 !important;
}

.award_icon_2  svg {
   fill: #2596be;
}

.award_icon_3 svg {
  fill:rgba(0, 128, 0 ,1);
}

.ibMath_award_icon svg {
    width: 35px;
    height: 35px;
}