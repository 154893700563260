

.studying_section{
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 40px;
}

.st_svg{
  text-align: center;
}

.st_svg .svg_text{
  font-weight: 600;
  font-size: 17px;
  color: #6E6E6E;
}

.st-title{
  margin-top: 25px;
  margin-bottom: 20px;
}

.st_items{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
 flex-wrap: wrap;
 width: 600px;
}

.st_items .st_item{
 padding:10px 40px;
 border-radius: 999px;
 background-color: #EEF2FE;
 color:#265BF6;
 font-weight: 600;
 cursor: pointer;
}
.st_items .st_item:hover{
  background-color: #d5ddf7;

}

.st_container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.get_started_title{
  cursor: pointer;
}

.get_started_title svg{
  opacity: 0;
  transform: translateX(-30px);
  transition: .2s ease;
}

.get_started_title:hover svg{
  opacity: 1;
  transform: translateX(0px);

}