

.question_dist_container{
}

.question_dist{
  display: flex;
  width: 100%;
  justify-content: space-between;
}


.question_dist_table_container{
  margin-block: 10px;
  width: 100%;
}

.question_dist_table_container table{
  width: 100%;
}

.question_dist_table_container thead th{
  padding-block: 15px !important;
  color: #222;
  font-weight: normal;
  font-size: 15px;
}

.question_dist_table_container tfoot{
  border-top: 1px solid #dbdbdb;
}

.question_dist_table_container tfoot td{
  padding-block:10px !important ;
}
.question_dist_table_container table td,
.question_dist_table_container table th{
  padding-inline: 20px;
  padding-block: 5px;
  font-size: 12px !important;
  font-weight: bolder;
}


.topic_row{
  background-color: rgb(242, 242, 242);
  
}
.topic_row td:first-child{
  border-radius: 20px 0 0 20px;
}

.topic_row td:last-child{
  border-radius: 0 20px 20px 0;
  
}