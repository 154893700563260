.eff-rev-container {
  background-color: #fff;
  padding: 40px 0 ;
  display: flex;
  flex-direction: column;
  gap: 30px;
}


.eff_rev_items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}


.eff_rev_items .rev_item{
  padding: 40px;
  /* background-color: #EFE2FE; */
  border: 1.5px solid var(--green-1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}


.eff_rev_items .rev_item .rev-title{
  font-size: 23px;
  font-weight: 600;
  text-align: center;
}

.eff_rev_items .rev_item .rev-content{
  font-size: 14px;
  font-weight: 600;
  color: #383838;
  z-index: 2;
  text-align: center;
}


.rev_item_icon{
  width: 100px;
  margin-inline: auto;
  user-select: none;
  -webkit-user-drag: none;
}
.rev_item_icon img{
  width: 100%;
  -webkit-user-drag: none;
  user-select: none;
}

.rev_icon{
  fill: transparent;
  stroke-width: 6px;
}