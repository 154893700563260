.ibMath_content_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 1000px;
    margin: 40px auto;
}

.ibMath_content_box {
    display: flex;
    gap: 10px;
    align-items: center;
}

.ibMath_content_box img {
    max-width: 500px;
    border-radius: 40px;
    width: 100%;
}

.ibMath_content_text-box h2 {
    font-size: 1.875rem;
}

.ibMath_content_text-box p {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
}

.ibMath_content_box {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 50px;
    margin: 30px 0px;
}

.ibMath_content_box div {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.ibMath_content_box div p {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0px;
}

.ibMath_content_paragraph {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 10px 0px;
}