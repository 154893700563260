.quiz_card {
  width: 100%;
  text-align: center;
  border-radius: 0.8rem;
  background-color: white;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease;
  position: relative;
  overflow: hidden;
}

.quiz_card h4 {
  padding: 10px;
  border-radius: 0 !;
  font-size: 16px;
  /* background: linear-gradient(103deg, rgb(242, 242, 242) 0%, rgb(247, 247, 247) 100%); */
    border-radius: inherit;
  /* animation: shimmer 1s infinite alternate; */
  position: relative;
  z-index: 4;
  
}

.quiz_card .quiz_card_info {
  padding: 10px;
  background-size: 300%;
  background-position: 100% 0;
  border-radius: inherit;
  font-size: 13px;
  position: relative;
  z-index: 4;

}
  .quiz_card .quiz_card_info > div:first-child{
    border-right: 1px solid #aaa;
  }

@keyframes shimmer {
  to {
    background-position: -100% 0;
  }
}

.quiz_card:hover {
  transform: translateY(-10px);
}

.quiz_card:hover svg path{
  width: 400px !important;
  height: 300px !important;
} 