.practice_exams_page{

}


.practice_exams_page .rev_types{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  align-content: center;
  justify-items: center;
  justify-content: center;
  margin-block: 30px;
  margin-inline: auto;
  
}


.practice_exams_page .rev_types .rev_type{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: var(--green-3);
  border: 2px solid var(--green-3);
  box-shadow: 0 0 10px #4a6655;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  text-align: center;
  color: #FFF !important;
  cursor: pointer;
  transition: .2s ease;
}
.practice_exams_page .rev_types .rev_type h5{
  font-size: 13px;
  color: #fFF;
}
.practice_exams_page .rev_types .rev_type p{
  font-size: 11px;

}

.practice_exams_page .rev_types .rev_type:hover{
  width: 170px;
  height: 170px;
  border: 2px solid var(--green-1);
}

