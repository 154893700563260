


.dark-background {
  --background-color: #00712D;
  --default-color: #e2f1e8;
  --heading-color: #ffffff;
  --surface-color: #404356;
  --contrast-color: #ffffff;
}


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(104deg, rgba(18,85,45,1) 33%, rgba(25,105,56,1) 52%, rgba(6,42,21,1) 100%);
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  min-height: 75vh;
  padding-top: 60px;
}

.hero h2 {
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

.hero h2 span {
  text-decoration: underline;
}

.hero p {
  max-width: 80%;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: var(--default-color);
}

.hero .carousel-control-prev,
.hero .carousel-control-next {
  width: 10%;
}

.hero .carousel-control-next-icon,
.hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}



@media (max-width: 768px) {
  .hero .carousel-container {
    min-height: 90vh;
  }

  .hero h2 {
    font-size: 28px;
  }
}

.hero .hero-waves {
  display: block;
  width: 100%;
  height: 60px;
  position: relative;
}

.hero .wave1 use {
  animation: move-forever1 10s linear infinite;
  animation-delay: -2s;
  fill: var(--default-color);
  opacity: 0.6;
}

.hero .wave2 use {
  animation: move-forever2 8s linear infinite;
  animation-delay: -2s;
  fill: var(--default-color);
  opacity: 0.4;
}

.hero .wave3 use {
  animation: move-forever3 6s linear infinite;
  animation-delay: -2s;
  fill: var(--default-color);
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}




.heroContent{
  display: flex;
  width: 100%;
  margin-block: 100px;
}

.try_for_free{
  padding: 8px 50px;
  border-radius: 999px;
  border: 1.5px solid #FFF;
  color: #FFF;
  font-size: 16px;
  cursor: pointer;
  transition: .2s ease;
}

.try_for_free.unlock{
  border: 1.5px solid #FF9100;
  background-color: #FF9100;
  
}


.try_for_free:hover{
  background-color: #FFF;
  color:var(--background-color)
}


.try_for_free.unlock:hover{
  background-color: #d67b05;
  color:#FFF
}

/* features styles */ 


.feat_container *{
  color: var(--green-1);
}
.feat_container .right_feet{
  text-align: end;
  border-radius: 20px;
  overflow: hidden;
  padding: 30px;
}

.feat_container .right_feet img{
  width:80%;
  border-radius: 20px;
  box-shadow: 0 0 10px #d1d1d1;

}

.features_section{
  background-color: #e2f1e8;
}