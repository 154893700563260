.subj_card_container{
  /* width: 400px; */
  border: 1px solid;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #e5f8e3;
  border-radius: 40px;
  gap: 20px;
  position: relative;
  overflow: hidden;
}

.subj_card_container .card_title{
  font-size: 25px;
  z-index: 2;
 text-align: center;
  font-weight: 500;
  color: #222;
  border-radius: 20px;
}

.subj_card_container .subj_items{
  display: grid;
  width: 200px;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  z-index: 2;

  gap: 15px;
}

.subj_card_container .subj_items .subj_item{
  background-color: var(--green-3);
  text-align: center;
  padding: 4px 2px;
  border-radius: 999px;
  cursor: pointer;
  padding: 3px 2px;
  transition: .2s ease;
  z-index: 2;
}

.subj_card_container .subj_items .subj_item:hover{
  background-color: var(--green-2);
}

.subj_card_container .icon_1{
  position: absolute;
  top: -20px;
  left: -10px;
}

.subj_card_container .icon_2{
  position: absolute;
  bottom: -20px !important;
  right: -10px !important;

}

