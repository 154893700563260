

.c-dropdown {
  position: relative;
  display: inline-block;
}

.c-dropdown-button {
  all: unset;
  border: none;
  cursor: pointer;
}

.c-dropdown-button:focus {
  outline: none;
}

.c-dropdown-menu {
  display: block;
  position: absolute;
  background-color: #FFF;
  width: max-content;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: 10px;
  padding:10px
}

.c-dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  transition: .3s ease;
  border-radius: 5px;
  
}

.c-dropdown-item:hover {
  background-color: #f1f1f1;
}
