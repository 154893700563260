

.group_section_container{
 display: flex;
flex-direction: column;
gap: 20px;
}

.group_section_container .groups_content{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;

}