.math_memory_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 500px;
    overflow: auto;
    padding: 10px;
}

.math_memory-box {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 15px 10px;
    border-radius: 15px;
    max-width: 250px;
    width: 100%;
}

.math_memory-box h5 {
    font-size: 15px;
    margin: 0px;
    line-height: 1.6;
}

.math_memory-box p {
    font-size: 14px;
    color: #555;
    line-height: 1.6;
}

.math_memory-box img {
    width: 250px;
}