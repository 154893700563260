.footer-section h1,
.footer-section h2,
.footer-section h3,
.footer-section h4,
.footer-section h5,
.footer-section h6 {}

.footer-section section {
    padding: 60px 0;
    min-height: 100vh;
}

.footer-section a,
.footer-section a:hover,
.footer-section a:focus,
.footer-section a:active {
    text-decoration: none;
    outline: none;
}

.footer-section a,
.footer-section a:active,
.footer-section a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

.footer-section ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.footer-section img {
max-width: 100%;
height: auto;
}.footer-section {
background-color: #233243;
position: relative;
overflow: hidden;
z-index: 9;
}
.footer-section:before {
content: '';
position: absolute;
top: -146%;
left: -18%;
width: 44%;
height: 257%;
transform: rotate(54deg);
background-color: rgb(31, 47, 64);
-webkit-transform: rotate(54deg);
-moz-transform: rotate(54deg);
-ms-transform: rotate(54deg);
-o-transform: rotate(54deg);
z-index: -10;
}
.footer-section:after {
position: absolute;
content: '';
background-color: rgb(31, 47, 64);
top: -24%;
right: 4%;
width: 26%;
height: 264%;
transform: rotate(44deg);
-webkit-transform: rotate(44deg);
-moz-transform: rotate(44deg);
-ms-transform: rotate(44deg);
-o-transform: rotate(44deg);
z-index: -10;
}
.footer-top {
padding-top: 96px;
padding-bottom: 50px;
}
.footer-top p,
.company-footer-contact-list li {
color: #ffffff;
}
.company-footer-contact-list {
margin-top: 10px;
}
.company-footer-contact-list li {
display: flex;
display: -webkit-flex;
align-items: center;
}
.company-footer-contact-list li+li {
margin-top: 5px;
}
.company-footer-contact-list li i {
margin-right: 10px;
font-size: 20px;
display: inline-block;
}

.footer-top .site-logo {
margin-bottom: 25px;
display: block;
max-width: 170px;
}
.widget-title {
text-transform: capitalize;
}
.footer-top .widget-title {
color: #ffffff;
margin-bottom: 40px;
}
.courses-link-list li+li {
margin-top: 10px;
}
.courses-link-list li a {
color: #ffffff;
text-transform: capitalize;
font-family: var(--para-font);
font-weight: 400;
}
.courses-link-list li a:hover {
color: green;
}
.courses-link-list li i {
margin-right: 5px;
}
.footer-top .small-post-title a {
font-family: var(--para-font);
color: #ffffff;
font-weight: 400;
}
.small-post-item .post-date {
color: green;
margin-bottom: 3px;
font-family: var(--para-font);
font-weight: 400;
}
.small-post-list li+li {
margin-top: 30px;
}
.news-letter-form {
margin-top: 15px;
}
.news-letter-form input {
width: 100%;
padding: 12px 25px;
border-radius: 5px;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
-ms-border-radius: 5px;
-o-border-radius: 5px;
border: none;
}
.news-letter-form input[type="submit"] {
width: auto;
border: none;
background-color: green;
padding: 9px 30px;
border-radius: 5px;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
-ms-border-radius: 5px;
-o-border-radius: 5px;
color: #ffffff;
margin-top: 10px;
}
.footer-bottom {
padding: 13px 0;
border-top: 1px solid rgba(255, 255, 255, 0.149);
}
.copy-right-text {
color: #ffffff;
}
.copy-right-text a {
color: green;
}
.terms-privacy li+li {
margin-left: 30px;
}
.terms-privacy li a {
color: #ffffff;
position: relative;
}
.terms-privacy li a:after {
position: absolute;
content: '-';
color: #ffffff;
display: inline-block;
top: 0;
right: -18px;
}
.terms-privacy li+li a:after {
display: none;
}