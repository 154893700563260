.ass_select_subtopics{
  
}


.ass_select_subtopics .topics{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: .2s ease;
}
 .topics .topic{
  transition: .2s ease;
  cursor: pointer;
}
 .topics .topic.active{
  background-color: #e8e8e8;

}

.ass_select_subtopics .topics .topic:hover{
  background-color: #e8e8e8;
}