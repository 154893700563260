
.custom_q_button{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 3px 5px;
  border-radius: 9999px;
  cursor: pointer;
}
  .custom_q_button .icon{
    line-height: 0;
  }

  .custom_q_button .text{
    font-size: 14px;
    font-weight: 600;
  }