
.assessment_select{
  display: flex;
}

.assessment_select .topics_side{
  
}

.topic_branch{
  padding: 10px 20px;
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  margin-block: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  transition: .2s ease;
  cursor: pointer;
}

.topic_branch:hover{
  background-color: #f2f2f2;
}

.topic_branch.active{
  background-color: var(--green-2);
}
  .topic_branch.active .topic_name{
    color: #FFF;
  }

.topic_branch_infos{
  display: flex;
  gap: 2px;
  align-items: center;
}

.branch_questions_container{
  padding: 10px !important;
  background-color: #efefef;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 400px;
  overflow: auto;
}


.droppalble_branch .branch_question,
.branch_questions_container .branch_question{
 background-color: #fff;
 padding: 10px !important;
 border-radius: 10px;
 border: 1px solid #707070;
 display: flex;
 grid-area: 10px;
 height: 130px;
 gap: 10px;
 overflow: hidden;
}