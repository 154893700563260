.quiz_questions_container{
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.jump_number{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1.5px solid var(--green-1);
  cursor: pointer;
  color: #222;
  font-size: 13px;
  font-weight: bolder;
}

.hump_numbers_container{
  gap: 4px;
  display: flex;
  margin-inline: center;
  justify-content: center;
  width: 200px;
  align-items: center;
  flex-wrap: wrap;
}