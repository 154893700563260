

.more_m_r_container{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 40px 0 ;

}

.more_m_r_container .title{
  font-size: 40px !important;
  margin-inline: auto;
  text-align: center;
  width: 1000px;
}

.mm_content{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  margin-top: 50px;
  margin-inline: auto;

}


.mm_item > div{
  
}

.mm_item{
  text-align: center;
  transition: .5s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transform: translateY(0) !important;
  transition-delay: 0 !important;
  position: relative;
  padding: 20px;
}
.mm_item .mm_title{
  z-index: 4;
  transition: .2s ease;
}

.mm_item:hover .mm_title{
  color: #fff;
}

.mm_overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 10px 10px 100px;
  
  transition: .3s ease;
  background-color: var(--green-2);
}

.mm_item:hover .mm_overlay{
  width: 100%;
  height: 100%;
  border-radius:   50px 50px ;
}

.mm_item .mm_icon {
  z-index: 4;
}
.mm_item .mm_icon svg{
  max-width: 55px !important;
  border-radius: 50% !important;
}

