.revise_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 500px;
    overflow: auto;
    padding: 10px;
}

.revise-box {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 15px 10px;
    border-radius: 15px;
    max-width: 250px;
    width: 100%;
}

.revise-box img {
    width: 250px;
    margin: 5px auto;
}

.revise-box h5 ,
.revise-box p{
    margin: 0px;
    /* font-size: ; */
}

.revise-box h5 {
    margin: 5px 0px;
    font-size: 17px;
}

.revise-box p {
    margin: 2px 0px;
    font-size: 13px !important;
    color: #2221216d;
}