.ibMath_tips_container {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: 30px auto;
    padding: 10px 0px;
}

.ibMath_tips_container h2 {
    font-size: 1.875rem;
    font-weight: 600;
}

.ibMath_tips_container ol {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 1.1rem;
    margin-top: 18px;
}