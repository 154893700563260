

.rev_assessment{

}

.dropdown_controle{
 width: fit-content; 
 padding: 5px 10px;
 background-color: #ededed;
}



.characters {
  list-style: none;
  padding-left: 0;
}

.characters li {
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  border-radius: .2em;
  padding: .5em .8em .5em .5em;
  margin-bottom: 1em;
}

.characters p {
  max-width: none;
  font-weight: bold;
  margin: 0;
}

.characters-thumb {
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  background-color: #e8e8e8;
  padding: .5em;
  margin-right: .5em;
}

.characters-thumb img {
  display: block;
  width: 100%;
  height: auto;
}

.droppable_container{
  width: 100%;
  border-radius: 10px;
}

.drop_handler{
  background-color: var(--green-2);
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  font-size: 30px;
  border-radius: 10px 0 0 10px;
}

.droppalble_item{
  margin-bottom: 8px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex !important;
}

.droppalbe_question_content{
  padding: 20px;
  width: 100%;
}