

.get_started_container{
  background-color: #fff;
  padding: 20px;
}


.get_started_content{
 position: relative;
 padding: 30px;
 display: flex;
 align-items: center;
 justify-content: center;
  background-color: var(--green-4);
 flex-direction: column;
 color:#fff;
 border-radius: 20px;
 overflow: hidden;
}

.get_started_content .title,
.get_started_content p{
  z-index: 2;
}

.get_started_content p{
  margin-top: 20px;
}

.gs_wave{
  position: absolute;
}

.gs_wave.gs_wave_1{
  top: 0;
  right:0;
}

.gs_wave_2{
  transform: rotate(180deg);
  bottom: 0;
  left: 0;
}